import { FC, HTMLProps, MouseEvent, PropsWithChildren } from 'react';
import type { LinkField } from '@prismicio/client';
import clsx from 'clsx';
import { PrismicNextLink } from '@prismicio/next';
import { CustomLink } from 'ui/components/CustomLink/CustomLink';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import { SVGLibrary } from 'ui/svgs/library';

interface LinkProps
  extends Omit<HTMLProps<HTMLAnchorElement | HTMLButtonElement>, 'onClick' | 'size' | 'type' > {
    className?: string;
    disabled?: boolean,
    field?: LinkField;
    href?: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
    underline?: boolean,
}

export const CtaLink: FC<PropsWithChildren<LinkProps>> = props => {
  const {
    children,
    className,
    color,
    disabled,
    field,
    href,
    onClick,
    rel,
    target,
    type,
    underline,
    ...rest
  } = props;

  const classNames = clsx(
    '[ no-underline ][ flex items-center gap-x-1.5 ][ group/link ]',
    className,
  );

  if (onClick) {
    return (
      <button
        onClick={onClick}
        type={type as any}
        disabled={disabled}
        className={classNames}
        {...(rest as HTMLProps<HTMLButtonElement>)}
      >
        <CtaContent text={children} underline={underline} />
      </button>
    );
  } else if (field) {
    return (
      <PrismicNextLink
        field={field}
        rel={rel}
        className={classNames}
      >
        <CtaContent text={children} underline={underline} />
      </PrismicNextLink>
    );
  } else if (href) {
    return (
      <CustomLink href={href} {...rest}>
        <a
          className={classNames}
          {...(rest as HTMLProps<HTMLAnchorElement>)}
        >
          <CtaContent text={children} underline={underline} />
        </a>
      </CustomLink>
    );
  } else {
    return (
      <span className={classNames}>
        <CtaContent text={children} underline={underline} />
      </span>
    );
  }
};

export const CtaContent = ({ text, underline }) => {
  return (
    <>
      <span className={underline === true ? '[ underline ]' : ''}>{text}</span>
      <ArrowForward fill="currentColor" className="[ transition-transform duration-300 ][ translate-x-0 group-hover/link:translate-x-1 ]" />
    </>
  );
};

CtaLink.defaultProps = {
  type: undefined,
  underline: true,
};
