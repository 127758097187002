import { FC, HTMLProps, MouseEvent, PropsWithChildren } from 'react';
import type { LinkField } from '@prismicio/client';
import clsx from 'clsx';
import { PrismicNextLink } from '@prismicio/next';
import { CustomLink } from 'ui/components/CustomLink/CustomLink';

interface ButtonProps
  extends Omit<HTMLProps<HTMLAnchorElement | HTMLButtonElement>, 'onClick' | 'size' | 'type' > {
    appearance?: 'solid' | 'outline';
    className?: string;
    color?: 'black' | 'blue' | 'white';
    disabled?: boolean,
    field?: LinkField;
    hasIcon?: 'left' | 'right' | 'only' | false;
    href?: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    size?: 'small' | 'default' | 'medium' | 'large';
    type?: 'button' | 'submit' | 'reset' | undefined;
}

export const CommonButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const {
    appearance,
    children,
    className,
    color,
    disabled,
    field,
    hasIcon,
    href,
    onClick,
    rel,
    size,
    target,
    type,
    ...rest
  } = props;

  const classNames = clsx(
    '[ transition-colors duration-200 ease-in-out ][ rounded-lg ][ focus:outline focus:outline-2 focus:outline-gray-0 ][ font-medium text-center ][ inline-flex justify-center items-center ]',
    {
      // appearance
      '[ border border-solid ]': appearance === 'outline',
      // appearance + color
      '[ bg-gray-0 hover:bg-gray-10 active:bg-gray-30 focus:bg-gray-30 ][ text-white ]': appearance === 'solid' && color === 'black',
      '[ bg-blue-50 hover:bg-blue-30 active:bg-blue-20 focus:bg-blue-20 ][ text-white ]': appearance === 'solid' && color === 'blue',
      '[ bg-white hover:bg-gray-90 active:bg-gray-85 focus::bg-gray-85 ][ text-gray-0 ]': appearance === 'solid' && color === 'white',
      '[ bg-transparent hover:bg-gray-0/5 active:bg-gray-0/10 focus:bg-gray-0/10 ][ border-gray-40 ][ text-gray-0 ]': appearance === 'outline' && color === 'black',
      '[ bg-transparent hover:bg-blue-50/10 active:bg-blue-50/20 focus:bg-blue-50/20 ][ border-blue-50 ][ text-blue-50 ]': appearance === 'outline' && color === 'blue',
      '[ bg-transparent hover:bg-white/10 active:bg-white/15 focus:bg-white0/15 ][ border-white ][ text-white ]': appearance === 'outline' && color === 'white',
      // hasIcon
      '[ gap-x-2 ]': hasIcon,
      '[ !px-0 ]': hasIcon === 'only',
      // hasIcon + size
      '[ [&_svg]:w-6 [&_svg]:h-6 ]': hasIcon && size !== 'small',
      '[ !pl-6 ]': hasIcon === 'left' && (size === 'large' || size === 'medium'),
      '[ !pr-6 ]': hasIcon === 'right' && (size === 'large' || size === 'medium'),
      '[ !pl-3.5 ]': hasIcon === 'left' && size === 'default',
      '[ !pr-3.5 ]': hasIcon === 'right' && size === 'default',
      '[ [&_svg]:w-5 [&_svg]:h-5 ]': hasIcon && size === 'small',
      '[ !pl-2.5 ]': hasIcon === 'left' && size === 'small',
      '[ !pr-2.5 ]': hasIcon === 'right' && size === 'small',
      // size
      '[ text-16 leading-tight ]': size !== 'small',
      '[ min-w-14 min-h-14 ][ px-6 py-4 ]': size === 'large',
      '[ min-w-12.5 min-h-12.5 ][ px-6 py-3 ]': size === 'medium',
      '[ min-w-11 min-h-11 ][ px-5 py-2 ]': size === 'default',
      '[ text-14 leading-none ][ min-w-8 min-h-8 ][ px-3 py-1 ]': size === 'small',
      // disabled
      '[ bg-gray-97 ][ border border-solid border-gray-85 ][ text-gray-60 ][ pointer-events-none ]': disabled,
    },
    className,
  );

  if (onClick) {
    return (
      <button
        onClick={onClick}
        type={type as any}
        disabled={disabled}
        className={classNames}
        {...(rest as HTMLProps<HTMLButtonElement>)}
      >
        {children}
      </button>
    );
  } else if (field) {
    return (
      <PrismicNextLink
        field={field}
        rel={rel}
        className={classNames}
      >
        {children}
      </PrismicNextLink>
    );
  } else if (href) {
    return (
      <CustomLink href={href} {...rest}>
        <a
          className={classNames}
          {...(rest as HTMLProps<HTMLAnchorElement>)}
        >
          {children}
        </a>
      </CustomLink>
    );
  }
};

CommonButton.defaultProps = {
  appearance: 'solid',
  color: 'black',
  hasIcon: false,
  size: 'default',
  type: undefined,
};
