import useTranslation from 'next-translate/useTranslation';
import { SVGLibrary } from 'ui/svgs/library';
import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CommonButton } from 'ui/components/CommonButton';
import styles from './GetStartedModal.module.scss';
import Getstarted from './Getstarted';

const GetStartedModal = ({ open, onClose }) => {
  // getstarted
  const { t, lang } = useTranslation('getstarted');
  const [_document, set_document] = useState(null);
  const getstartedData: any = t('.', {}, { returnObjects: true });

  useEffect(() => {
    set_document(document);
  }, []);

  return (
    _document &&
    createPortal(
      <div
        className={clsx(
          styles.modal,
          '[ transition-max-height duration-250 ease-in ][ bg-gray-0/85 !backdrop-blur-lg ][ !w-full !max-w-full overflow-y-scroll ][ fixed top-0 bottom-0 z-10000 ]',
          {
            '[ h-px ]': !open,
            '[ animate-fade-in ][ h-full ]': open,
          }
        )}
      >
        <div className="[ flex justify-end ][ px-12 py-6 ]">
          <CommonButton onClick={onClose} appearance="outline" color="white" hasIcon="only">
            <SVGLibrary name={'CloseModal'} fill="currentColor" />
          </CommonButton>
        </div>

        <div className="[ mb-6 ]">
          <Getstarted data={{ data: getstartedData }} />
        </div>
      </div>,
      document.getElementById('dropdownPortalElement'),
    )
  );
};

export default GetStartedModal;
