import { useFeature } from '@growthbook/growthbook-react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Loader } from 'ui/components/Loader';
import { PreviewBar } from 'ui/components/PreviewBar';
import { Footer } from 'ui/layout/Footer';
import { Header } from 'ui/layout/HeaderV2';
import { HeaderService } from 'ui/services/header/header.service';
import { useAppContext } from 'ui/utils/app-context';
import { HrefLangTags } from 'ui/utils/generateHrefLang/generateHrefLang';
import { isMobile } from 'ui/utils/isMobile';

const DynamicChatbotSection = dynamic(() => import('ui/components/ChatbotSection/ChatbotSection')) as any;
const DynamicBotpress = dynamic(() => import('ui/components/ChatbotSection/Botpress')) as any;

export interface LayoutProps extends PropsWithChildren {
  headerProps: any;
  footerProps: any;
  triggerCallbackOnAppLevel?: any;
  pathsWhereLocalesAreEnabled?: string[];
  // headerData: any;
}

export const Layout: FC<LayoutProps> = ({
  children,
  headerProps = null,
  footerProps = null,
  triggerCallbackOnAppLevel,
  pathsWhereLocalesAreEnabled,
}) => {
  const { isFallback, pathname } = useRouter();
  const isLocaleEnabledPath = pathsWhereLocalesAreEnabled.includes(pathname); // if the current page is i18n enabled, then add hreflang tags

  const [isBotAllowed, setIsBotAllowed] = useState(false);
  const [useBotpress, setUseBotpress] = useState(false);
  const { value: chatbotConfig } = useFeature('chatbot_config');
  const { visitor, impervaUserInfo } = useAppContext();

  useEffect(() => {
    if (visitor && chatbotConfig && !isMobile()) {
      setUseBotpress(chatbotConfig.useBotpress);
      HeaderService.getCountry().then(country => {
        const allowedCountries = chatbotConfig.allowedCountries || [];
        const allowedRoutes = chatbotConfig.allowedRoutes || [];
        if (process.env.NEXT_PUBLIC_SUBDOMAIN !== 'www') {
          setIsBotAllowed(allowedRoutes.includes(pathname));
        } else {
          setIsBotAllowed(allowedCountries.includes(country) && allowedRoutes.includes(pathname));
        }
      });
    }
  }, [visitor, chatbotConfig, pathname]);

  return (
    <>
      {isLocaleEnabledPath && <HrefLangTags />}
      <PreviewBar />
      <div className="layout [ flex flex-col ][ w-full ][ pt-18 ]">
        {/* <Header {...headerProps} triggerCallbackOnAppLevel={triggerCallbackOnAppLevel} /> */}
        <Header />
        {/* <SubHeader /> */}
        {isFallback ? <Loader /> : children}
        {isBotAllowed &&
          (useBotpress ? (
            <DynamicBotpress chatbotConfig={chatbotConfig} impervaUserInfo={impervaUserInfo} />
          ) : (
            <DynamicChatbotSection chatbotConfig={chatbotConfig} impervaUserInfo={impervaUserInfo} />
          ))}
        <Footer {...footerProps} />
      </div>
    </>
  );
};

Layout.defaultProps = {
  pathsWhereLocalesAreEnabled: [],
};
