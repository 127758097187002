import { SVGLibrary } from 'ui/svgs/library';
import { PrismicNextLink } from '@prismicio/next';
import clsx from 'clsx';
import { CtaLink } from 'ui/components/CtaLink';

const Getstarted = ({ loadedAsPage = false, data }) => {
  const { title, sub_title, items } = data.data || data;

  return (
    <div
      className={clsx(
        {
          '[ mx-16 my-0 ]': !loadedAsPage,
          '[ bg-gray-0 ][ ma-0 px-4 pt-0 pb-0 lg:pb-6 ]': loadedAsPage,
        }
    )}
    >
      <div className="[ text-white text-center ][ mx-0 my-6 lg:my-15 ]">
        <div className="[ font-medium text-26 lg:text-46 lg:tracking-tight ][ mb-6 ]">{title}</div>
        <div className="[ font-normal lg:font-medium text-14 lg:text-20 ]">{sub_title}</div>
      </div>

      <div className="[ flex flex-row flex-wrap lg:flex-nowrap justify-center gap-8 ][ mb-8 lg:mb-0 ]">
        {items.map(item => (
          <PrismicNextLink field={item.cta_link} target={item.cta_target} className="[ flex ][ text-white ]">
            <div
              key={item.title}
              className="[ bg-gray-10 ][ rounded-2xl ][ flex flex-col justify-between ][ max-w-104 ][ p-8 ]"
            >
              <div>
                <div className="[ w-12 h-12 ]">
                  <SVGLibrary name={item.icon} />
                </div>

                <div className="[ text-12 ][ mt-4 ]">{item.tag_line}</div>
                <div className="[ font-medium text-24 lg:text-28 lg:tracking-tight ][ mx-0 my-4 ]">{item.title}</div>
                <p className="[ text-16 text-gray-90 ][ mx-0 mt-0 mb-8 ]">{item.sub_title}</p>
              </div>

              <CtaLink className="[ text-14 font-semibold ]">
                {item.cta_text}
              </CtaLink>
            </div>
          </PrismicNextLink>
        ))}
      </div>
    </div>
  );
};

export default Getstarted;
